import React, { useState, useEffect } from 'react';
import logo from '../assets/logo.png';
import './LogoIntro.css';

const LogoIntro = () => {
    const [moveUp, setMoveUp] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setMoveUp(true);
        }, 1500); // 3 seconds delay

        return () => clearTimeout(timer); // Clear timer if component unmounts
    }, []);

    return (
        <div className={`logo-container ${moveUp ? 'move-up' : ''}`}>
            <img src={logo} alt="Logo" className="logo-image" />
        </div>
    );
};

export default LogoIntro;
