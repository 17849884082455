import React from 'react';
import './ContactSection.css';

const ContactSection = () => {
    return (
        <div className="contact-section">
            <div className="contact-content">
                <h2 className="section-title">Interested In
                    Working Together?</h2>
                <div className="contact-links">
                    <a href="mailto:abdelouahab@laaroussi.dev" className="contact-link">
                        <i className="fas fa-envelope"></i> <span></span>Email me
                    </a>
                    <a href="https://www.linkedin.com/in/alaaroussi" target="_blank" rel="noopener noreferrer" className="contact-link">
                        <i className="fab fa-linkedin"></i> <span></span>Linkedin
                    </a>
                    <a href="https://github.com/abla001" target="_blank" rel="noopener noreferrer" className="contact-link">
                        <i className="fab fa-github"></i> <span></span>Github
                    </a>

                </div>
            </div>
        </div>
    );
};

export default ContactSection;