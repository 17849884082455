import React, { useEffect, useRef } from 'react';
import './AboutMeSection.css';
import myPicture from '../assets/me.jpg'; // Replace with the actual path to your picture

const AboutMeSection = () => {
    const topLeftCoverRef = useRef(null);
    const bottomRightCoverRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const maxSize = 100; // Maximum percentage size of the covers
            const minSize = 0;   // Minimum size of the covers to fully reveal the image

            if (imageRef.current) {
                const rect = imageRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;

                // Determine how much of the image is within the viewport
                const visibleHeight = Math.max(0, Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0));
                const visibilityRatio = visibleHeight / rect.height;

                // Calculate new size based on scroll visibility
                const newSize = Math.max(minSize, maxSize * (1 - visibilityRatio));

                if (topLeftCoverRef.current && bottomRightCoverRef.current) {
                    topLeftCoverRef.current.style.width = `${newSize}%`;
                    topLeftCoverRef.current.style.height = `${newSize}%`;
                    bottomRightCoverRef.current.style.width = `${newSize}%`;
                    bottomRightCoverRef.current.style.height = `${newSize}%`;
                }
            }
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Perform initial scroll calculation
        handleScroll();

        return () => {
            // Clean up the scroll event listener
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="about-me-section">
            <div className="about-me-content">
                <div className="about-me-text">
                    <h2 className="section-title title-no-margin">About Me</h2>
                    <p>
                        I’m Abdelouahab Laaroussi, a Software Engineer with a passion for building impactful and efficient software solutions. I graduated with an Engineering degree from Bordeaux-INP ENSEIRB-MATMECA, and over the past three years, I’ve worked at Odoo, where I honed my skills in full-stack development, cloud infrastructure, and performance optimization.
                        <br />
                        <br />
                        My expertise spans multiple programming languages and frameworks, and I have a particular interest in writing secure, scalable code. Beyond professional work, I enjoy game development and have completed several personal projects in Unity3D.
                        <br />
                        <br />
                        I thrive in collaborative environments and am always eager to take on new challenges to create innovative solutions.
                    </p>
                </div>
                <div className="about-me-image-wrapper" ref={imageRef}>
                    <div ref={topLeftCoverRef} className="top-left-cover"></div>
                    <div ref={bottomRightCoverRef} className="bottom-right-cover"></div>
                    <img src={myPicture} alt="Abdelouahab Laaroussi" className="about-me-image" />
                </div>
            </div>
        </div>
    );
};

export default AboutMeSection;
