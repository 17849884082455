import React, { useEffect, useRef } from 'react';
import rondaImg from '../assets/ronda.png';
import fallBallzImg from '../assets/fallballz.png';
import bloxorzImg from '../assets/bloxorz.png';
import './ProjectSection.css';

const projects = [
    {
        id: 1,
        image: rondaImg, // Replace with your image path
        name: 'Ronda',
        technologies: ['Unity3D', 'C#', 'NodeJS/TypeScript', 'Docker', 'Firebase'],
        description: 'A multiplayer card game with more than 50k Downloads on Google Play and App Store.',
        url: 'https://ronda.website',
    },
    {
        id: 2,
        image: fallBallzImg, // Replace with your image path
        name: 'Fall Ballz',
        technologies: ['Unity3D', 'C#'],
        description: 'A time killer game with more than 30k Downloads on Google Play and App Store.',
        url: 'https://fall-ballz.web.app/',
    },
    {
        id: 3,
        image: bloxorzImg, // Replace with your image path
        name: 'Bloxorz Maker',
        technologies: ['Unity3D', 'C#', 'firebase'],
        description: 'A puzzle game with feature to create and share your own levels.',
        url: 'https://bloxorzmaker.web.app/',
    },
    // Add more projects as needed
];

const ProjectSection = () => {
    const projectRefs = useRef([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.2, // 20% of the element is visible
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in');
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        projectRefs.current.forEach((project) => {
            if (project) {
                observer.observe(project);
            }
        });

        return () => {
            projectRefs.current.forEach((project) => {
                if (project) {
                    observer.unobserve(project);
                }
            });
        };
    }, []);
    return (
        <div className="project-section">
            <h1 className="section-title">Selected Work</h1>
            <div className="project-list">
                {projects.map((project, index) => (
                    <a href={project.url} className="project-link" key={project.id} target="_blank" rel="noopener noreferrer">
                        <div
                            className="project-item"
                            ref={(el) => (projectRefs.current[index] = el)} // Add ref for IntersectionObserver
                        >
                            <img src={project.image} alt={project.name} className="project-image" />
                            <div className="project-details">
                                <h3 className="project-name">{project.name}</h3>
                                <p className="project-technologies">{project.technologies.join(', ')}</p>
                                <p className="project-description">{project.description}</p>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default ProjectSection;
