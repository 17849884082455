import React, { useState, useEffect } from 'react';
import './NameSection.css';

const NameSection = () => {
    const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setScrollPos(scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="name-section">
            <h1
                className="first-name"
                style={{
                    transform: `translateX(${-scrollPos * 2.5}px)`, // Move left on scroll
                }}
            >
                ABDELOUAHAB
            </h1>
            <h1
                className="last-name"
                style={{
                    transform: `translateX(${scrollPos * 2.5}px)`, // Move right on scroll
                }}
            >
                LAAROUSSI
            </h1>
            <h2 className="title">Software Engineer based in Europe</h2>
        </div>
    );
};

export default NameSection;
