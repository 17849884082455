import React from 'react';
import LogoIntro from './components/LogoIntro';
import NameSection from './components/NameSection';
import ProjectSection from './components/ProjectSection';
import SkillsSection from './components/SkillsSection';
import './App.css';
import AboutMeSection from './components/AboutMeSection';
import ContactSection from './components/ContactSection';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
    return (
        <div className="App">
            <LogoIntro />

            <div className="cover left-cover"></div>
            <div className="cover right-cover"></div>

            <div className="content-wrapper">
                <NameSection />
                <ProjectSection />
                <SkillsSection />
                <AboutMeSection />
                <ContactSection />
            </div>
        </div>
    );
}

export default App;
