import React, { useEffect, useRef } from 'react';
import './SkillsSection.css';

const skills = [
    { id: 1, title: 'Software Engineering & Architecture', description: 'Strong foundation in OOP and design patterns, designing scalable, maintainable software architectures.' },
    { id: 2, title: 'Full-Stack Development', description: 'Experienced in building backends with NodeJS/Python and frontends using AngularJS and ReactJS. Developed enterprise-level solutions using Odoo frameworks, focusing on performance and user experience.' },
    { id: 3, title: 'Performance Optimization & Security', description: 'Optimized software for speed and efficiency while ensuring security through secure coding practices learned during my cybersecurity specialization.' },
    { id: 4, title: 'Game Development', description: 'Worked on personal projects using Unity3D and C#, focusing on gameplay mechanics and optimizing performance for various platforms.' },
    { id: 5, title: 'DevOps & Cloud', description: 'Experience with Docker, Kubernetes, Firebase, and AWS (EC2) for scalable application deployment and containerization.' },
    { id: 6, title: 'Database Management', description: 'Worked with SQL and NoSQL databases, including MySQL, PostgreSQL, and Firebase Firestore.' },
    { id: 7, title: 'Problem-Solving & Debugging', description: 'Adept at identifying and solving complex technical issues, with a methodical approach to debugging and optimizing code.' },
    // Add more skills as needed
];

const SkillsSection = () => {
    const skillRefs = useRef([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1,
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('in-view');
                } else {
                    entry.target.classList.remove('in-view');
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        skillRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            skillRefs.current.forEach((ref) => {
                if (ref) {
                    observer.unobserve(ref);
                }
            });
        };
    }, []);

    return (
        <div className="skills-section">
            <div className="skills-list">
                <div>
                    <h2 className="section-title">My Skills</h2>
                </div>
                {skills.map((skill, index) => (
                    <div
                        className={`skill-card ${index === 0 ? 'first-skill-card' : ''} ${index % 2 === 0 ? 'scroll-left' : 'scroll-right'}`}
                        key={skill.id}
                        ref={(el) => (skillRefs.current[index] = el)}
                    >
                        <h3 className="skill-title">{skill.title}</h3>
                        <p className="skill-description">{skill.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SkillsSection;